var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "q-form",
    { ref: "editForm" },
    [
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm",
          attrs: { title: "기본정보", noMarginPadding: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "과목명",
                    value: _vm.educationInfo.educationName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육과정",
                    value: _vm.educationInfo.educationCourseName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육종류1",
                    value: _vm.educationInfo.educationKindCdLargeName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "교육종류2",
                    value: _vm.educationInfo.educationKindCdSmallName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "사업장",
                    value: _vm.educationInfo.plantName,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6" },
              [
                _c("c-label-text", {
                  attrs: {
                    title: "주관부서",
                    value: _vm.educationInfo.departmentDeptName,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailForm mobilevod mobilevod-hide",
          attrs: { title: "동영상 시청", noMarginPadding: true },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _c(
              "div",
              { staticClass: "player-center text-center col-12" },
              [
                _c("video-player", {
                  ref: "videoPlayer",
                  attrs: { options: _vm.playerOptions },
                  on: {
                    playing: _vm.onPlayerStart,
                    play: _vm.onPlay,
                    pause: _vm.onPause,
                    ended: function ($event) {
                      return _vm.onPlayerEnded($event)
                    },
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "vod-label-layer col-12" }, [
              _vm.isEduEnd
                ? _c("div", { staticClass: "vod-label-div text-center" }, [
                    _vm._v("이수완료 : " + _vm._s(_vm.isEduEndDate)),
                  ])
                : _vm._e(),
              !_vm.isEduEnd
                ? _c("div", { staticClass: "vod-label-div text-center" }, [
                    _vm._v(
                      "재생 시간 : " +
                        _vm._s(_vm.vodPlayDuration) +
                        " / " +
                        _vm._s(_vm.vodTotalDuration)
                    ),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "vod-label-div text-center" },
                [
                  _vm.isFirstPlay &&
                  !_vm.isPlay &&
                  !_vm.isEduEnd &&
                  !_vm.disabled
                    ? _c("c-btn", {
                        staticClass: "full-width",
                        attrs: {
                          label: "처음부터 재생",
                          icon: "skip_next",
                          color: "orange",
                        },
                        on: { btnClicked: _vm.onPlayerPlayFirst },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "vod-label-div text-center" },
                [
                  !_vm.isEduEnd && !_vm.disabled
                    ? _c("c-btn", {
                        staticClass: "full-width",
                        attrs: {
                          label: _vm.playLabel,
                          icon: _vm.playIcon,
                          color: _vm.playColor,
                        },
                        on: { btnClicked: _vm.onPlayerPlay },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "c-card",
        {
          staticClass: "cardClassDetailInfo",
          attrs: { title: "서명 및 의견" },
        },
        [
          _c("template", { slot: "card-detail" }, [
            _vm.educationInfo.isQuestionFlag === "N" && !_vm.isEduQuestionPass
              ? _c("div", { staticClass: "vod-label-div text-center" }, [
                  _vm._v("서명/의견은 문제풀이 후 입력할 수 있습니다."),
                ])
              : _vm._e(),
            _vm.educationInfo.isQuestionFlag === "Y" && !_vm.isEduQuestionPass
              ? _c("div", { staticClass: "vod-label-div text-center" }, [
                  _vm._v("총점 : " + _vm._s(_vm.educationInfo.score)),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.educationInfo.isQuestionFlag === "Y" ||
                      _vm.educationInfo.eduQuestionFlag === "N",
                    expression:
                      "educationInfo.isQuestionFlag === 'Y' || educationInfo.eduQuestionFlag === 'N'",
                  },
                ],
                staticClass: "col-xs-12 col-sm-12",
              },
              [
                _c(
                  "div",
                  { staticClass: "container" },
                  [
                    _c(
                      "div",
                      { staticClass: "row items-center all-pointer-events" },
                      [
                        _c(
                          "b",
                          [
                            _c("font", { attrs: { id: "signatureTitle" } }, [
                              _vm._v("  서명"),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("VueSignaturePad", {
                      ref: "signaturePad",
                      attrs: {
                        id: "signature",
                        height: "200px",
                        options: _vm.options,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.educationInfo.isQuestionFlag === "Y" ||
                      _vm.educationInfo.eduQuestionFlag === "N",
                    expression:
                      "educationInfo.isQuestionFlag === 'Y' || educationInfo.eduQuestionFlag === 'N'",
                  },
                ],
                staticClass:
                  "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
              },
              [
                _c("c-textarea", {
                  attrs: {
                    disabled: _vm.disabled,
                    rows: 4,
                    label: "의견",
                    name: "opinionContent",
                  },
                  model: {
                    value: _vm.educationInfo.opinionContent,
                    callback: function ($$v) {
                      _vm.$set(_vm.educationInfo, "opinionContent", $$v)
                    },
                    expression: "educationInfo.opinionContent",
                  },
                }),
              ],
              1
            ),
            _vm.disabled
              ? _c("div", { staticClass: "vod-label-div text-center" }, [
                  _vm._v("교육완료 또는 이수완료 되었습니다."),
                ])
              : _vm._e(),
          ]),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isEduQuestionPass && !_vm.disabled,
                      expression: "!isEduQuestionPass && !disabled",
                    },
                  ],
                  attrs: {
                    flat: "",
                    label: "문제풀이",
                    icon: "task_alt",
                    color: "teal-6",
                  },
                  on: { btnClicked: _vm.onPlayEnd2 },
                }),
                _vm.isEnd && !_vm.isPlay && !_vm.isEduEnd && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: "",
                        label: "서명지우기",
                        icon: "remove",
                        color: "red",
                      },
                      on: { btnClicked: _vm.onSighRemove },
                    })
                  : _vm._e(),
                _vm.isEnd && !_vm.isPlay && !_vm.isEduEnd && !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: "",
                        label: "이수완료",
                        icon: "task_alt",
                        color: "blue",
                      },
                      on: { btnClicked: _vm.onPlayEnd },
                    })
                  : _vm._e(),
                _vm.educationInfo.educationMethodCd != "EMC999999" &&
                !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: "",
                        label: "서명지우기",
                        color: "red",
                        showLoading: false,
                      },
                      on: { btnClicked: _vm.eraseSignature },
                    })
                  : _vm._e(),
                _vm.educationInfo.educationMethodCd != "EMC999999" &&
                !_vm.disabled
                  ? _c("c-btn", {
                      attrs: {
                        flat: "",
                        label: "이수완료",
                        color: "blue",
                        showLoading: false,
                      },
                      on: { btnClicked: _vm.saveAsSvg },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }